// ======================================================================
// IMPORTS
// ======================================================================

import axios from 'axios';
import { getEndpoint, updateMediaUrls } from 'js/utils';

// ======================================================================
// STATE
// ======================================================================

const isLocal = process.env.NODE_ENV === 'development';
const devWin = false;

const state = {
  gameId: null,
  store: null,
  identity: null,
  touchpoint: null,

  inited: false,
  content: null,
  selectMode: false,

  winner: null,
  alreadyWon: null,
  prizeName: null,
  prizeCode: null,

  sectorResult: null,
};

// ======================================================================
// REDUCERS
// ======================================================================

const reducers = {
  setState(rootState, payload) {
    console.log('%c--- setState ---', 'color:#1D9109');
    return { ...rootState, ...payload };
  },
};

// ======================================================================
// EFFECTS
// ======================================================================

const effects = (dispatch) => ({
  init(payload, rootState) {
    console.log('%c--- init ---', 'color:#91074A');
    if (payload.gameId) {
      console.log(payload);
      // save the game ID from URL
      dispatch.appModel.setState({
        gameId: payload.gameId,
        selectedUserId: payload.selectedUserId,
        tracking_ref: payload.tracking_ref,
        navigate: payload.navigate,
      });
      // next
      dispatch.appModel.getGameData();
    } else {
      dispatch.appModel.setState({
        inited: true,
      });
    }
  },

  getGameData(payload, rootState) {
    console.log('%c--- getGameData ---', 'color:#91074A');
    console.log(rootState.appModel.gameId);
    const { api, host } = getEndpoint();

    const searchParams = new URLSearchParams(window.location.search);

    const bypassBAT = searchParams.get('bypass-bat');
    if (rootState.appModel.tracking_ref && bypassBAT !== 'true') {
      const trackingURL = 'https://dev.mychoice-promotion.de/api/v1/spintowin';
      axios
        .post(trackingURL, { user: rootState.appModel.tracking_ref })
        .then((res) => {
          console.log(res);
          if (res.data.message === false) {
            dispatch.appModel.logError({
              message_code: 'spin2win.getGameData.2',
              message: 'API Error - ' + endpoint,
              post_vars: 'error setting tracking data',
            });
            rootState.appModel.navigate('/');
            dispatch.appModel.setState({
              inited: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch.appModel.logError({
            message_code: 'spin2win.getGameData.2',
            message: 'API Error - ' + endpoint,
            post_vars: err,
          });
          rootState.appModel.navigate('/=');
          dispatch.appModel.setState({
            inited: true,
          });
        });
    }
    const endpoint =
      'game-lookup/' +
      rootState.appModel.gameId +
      (rootState.appModel.selectedUserId ? `/${rootState.appModel.selectedUserId}` : '') +
      (rootState.appModel.tracking_ref ? `?tracking_ref=${rootState.appModel.tracking_ref}` : '');
    const url = api + endpoint + (host === 'localhost' ? '.json' : '');
    axios
      .get(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      .then((res) => {
        console.log(res);
        if (res && res.data && res.data.status && res.data.status === 'ok') {
          if (res.data.messages.responseCode === 'bat_user_id_validation_failed') {
            rootState.appModel.navigate('/invalid-id');
          }

          if (res.data.messages.responseCode === 'no_tracking_reference') {
            console.log('hello');
            console.log(dispatch.appModel);
            dispatch.appModel.setState({
              inited: true,
            });
            return;
          }

          if (res.data.messages.responseCode === 'GameIDLookup.5') {
            dispatch.appModel.setState({
              selectMode: true,
            });
            rootState.appModel.navigate(`${rootState.appModel.gameId}/select`);
          } else {
            // save required info
            dispatch.appModel.setState({
              store: res.data.data.store_uuid,
              touchpoint: res.data.data.touch_point,
              identity: res.data.data.qr_identity,
              event: res.data.data.event ? res.data.data.event : null,
              enable_replay_button: res.data.data.enable_replay_button,
              enable_events_tripple_tap: res.data.data.enable_events_tripple_tap,
            });

            const status = res.data.data.game_status;

            if (status.overall_status !== 'won' && !status.prize_availability) {
              rootState.appModel.navigate('/no-prizes');
            }

            if (status && status.overall_status === 'won') {
              dispatch.appModel.setState({
                winner: true,
                alreadyWon: true,
                prizeName: status.prize,
                prizeCode: status.code,
              });
            }

            dispatch.appModel.getJSON();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        rootState.appModel.navigate('/');
        dispatch.appModel.logError({
          message_code: 'spin2win.getGameData.2',
          message: 'API Error - ' + endpoint,
          post_vars: err,
        });
      });
  },

  getEvents(payload, rootState) {
    console.log('%c--- getEvents ---', 'color:#91074A');
    console.log(rootState.appModel.gameId);
    const gameiD = payload && payload.gameId ? payload.gameId : rootState.appModel.gameId;
    const { api, host } = getEndpoint();
    const endpoint = 'events/' + gameiD; // Changed endpoint to 'events'
    const url = api + endpoint + (host === 'localhost' ? '.json' : '');
    axios
      .get(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      .then((res) => {
        console.log(res);
        if (res && res.data && res.data.status && res.data.status === 'ok') {
          dispatch.appModel.setState({
            events: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch.appModel.logError({
          message_code: 'spin2win.getEvents.2',
          message: 'API Error - ' + endpoint,
          post_vars: err,
        });
        rootState.appModel.navigate('/');
        dispatch.appModel.setState({
          inited: true,
        });
      });
  },

  getJSON(payload, rootState) {
    console.log('%c--- getJSON ---', 'color:#91074A');
    const { api, host } = getEndpoint();
    const endpoint = 'get/' + rootState.appModel.store + '/device-data/' + rootState.appModel.touchpoint;
    const url = api + endpoint + (host === 'localhost' ? '.json' : '');

    axios
      .get(url, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      })
      .then((res) => {
        console.log(res);
        if (res && res.data && res.data.data) {
          // const externalAssetPassthroughArray = data.ignore_external_asset_passthrough;
          dispatch.appModel.setState({
            inited: true,
            content: updateMediaUrls(res.data.data, [], rootState.appModel.touchpoint),
          });

          if (rootState.appModel.event && Object.values(rootState.appModel.event).length > 0) {
            dispatch.appModel.getEvents();
          }
        } else {
          dispatch.appModel.logError({
            message_code: 'spin2win.getJSON.1',
            message: 'API Error - ' + endpoint,
            post_vars: res,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch.appModel.logError({
          message_code: 'spin2win.getJSON.2',
          message: 'API Error - ' + endpoint,
          post_vars: err,
        });
      });
  },

  setEvent(payload, rootState) {
    console.log('%c--- setEvent ---', 'color:#91074A');
    const { api, host } = getEndpoint();
    var endpoint = 'events/' + rootState.appModel.gameId + `/${payload.store_id}`;
    if (isLocal && host === 'localhost') {
      endpoint = 'post/events/' + rootState.appModel.gameId;
    }
    const url = api + endpoint + (host === 'localhost' ? '.json' : '');
    if (host === 'localhost') {
      axios
        .get(url, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        })
        .then((res) => {
          console.log(res);
          // dispatch.appModel.getJSON();
        })
        .catch((err) => {
          // console.log(err);
          dispatch.appModel.logError({
            message_code: 'spin2win.setEvent.1',
            message: 'API Error - ' + endpoint,
            post_vars: err,
          });
        });
    } else {
      axios
        .post(
          url,
          {
            id: payload.id,
          },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          console.log(res);
          // dispatch.appModel.getGameData();
        })
        .catch((err) => {
          // console.log(err);
          dispatch.appModel.logError({
            message_code: 'spin2win.playGame.2',
            message: 'API Error - ' + endpoint,
            post_vars: err,
          });
        });
    }
  },

  playGame(payload, rootState) {
    console.log('%c--- playGame ---', 'color:#91074A');
    const { api, host } = getEndpoint();
    var endpoint = 'spintowin/play';
    if (isLocal && host === 'localhost') {
      if (devWin) {
        endpoint = 'spintowin/play-win';
      } else {
        endpoint = 'spintowin/play-lose';
      }
    }
    const url = api + endpoint + (host === 'localhost' ? '.json' : '');
    if (host === 'localhost') {
      axios
        .get(url, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        })
        .then((res) => {
          console.log(res);
          dispatch.appModel.playGameCallback(res.data);
        })
        .catch((err) => {
          console.log(err);
          dispatch.appModel.logError({
            message_code: 'spin2win.playGame.1',
            message: 'API Error - ' + endpoint,
            post_vars: err,
          });
        });
    } else {
      axios
        .post(
          url,
          {
            store: rootState.appModel.store,
            identity: rootState.appModel.identity,
          },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          console.log(res);
          dispatch.appModel.playGameCallback(res.data);
        })
        .catch((err) => {
          // console.log(err);
          dispatch.appModel.logError({
            message_code: 'spin2win.playGame.2',
            message: 'API Error - ' + endpoint,
            post_vars: err,
          });
        });
    }
  },

  playGameCallback(res, rootState) {
    if (res !== null && typeof res.status !== 'undefined' && res.status === 'ok') {
      if (res.messages.responseCode === 'winner') {
        // next
        dispatch.appModel.claimPrize();
      } else {
        dispatch.appModel.setState({
          winner: false,
        });
      }
    } else {
      dispatch.appModel.logError({
        message_code: 'spin2win.playGame.3',
        message: 'API Error - spintowin/play',
        post_vars: res,
      });
    }
  },

  claimPrize(payload, rootState) {
    console.log('%c--- claimPrize ---', 'color:#3183e0');
    const { api, host } = getEndpoint();
    const endpoint = 'spintowin/assign';
    const url = api + endpoint + (host === 'localhost' ? '.json' : '');
    if (host === 'localhost') {
      axios
        .get(url, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
          },
        })
        .then((res) => {
          console.log(res);
          dispatch.appModel.claimPrizeCallback(res.data);
        })
        .catch((err) => {
          // console.log(err);
          dispatch.appModel.logError({
            message_code: 'spin2win.claimPrize.1',
            message: 'API Error - ' + endpoint,
            post_vars: err,
          });
        });
    } else {
      axios
        .post(
          url,
          {
            store: rootState.appModel.store,
            identity: rootState.appModel.identity,
          },
          {
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          console.log(res);
          dispatch.appModel.claimPrizeCallback(res.data);
        })
        .catch((err) => {
          // console.log(err);
          dispatch.appModel.logError({
            message_code: 'spin2win.claimPrize.2',
            message: 'API Error - ' + endpoint,
            post_vars: err,
          });
        });
    }
  },

  claimPrizeCallback(res, rootState) {
    if (
      res !== null &&
      typeof res.status !== 'undefined' &&
      res.status === 'ok' &&
      res.messages.responseCode === 'assigned'
    ) {
      dispatch.appModel.setState({
        winner: true,
        prizeName: res.data.title,
        prizeCode: res.data.code,
      });

      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const trackingId = urlParams.get('tracking_ref');

      if (trackingId) {
        const trackingURL = 'https://dev.mychoice-promotion.de/api/v1/spintowin';
        axios
          .put(trackingURL, { user: trackingId, result: res.data.title })
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      dispatch.appModel.logError({
        message_code: 'spin2win.claimPrize.3',
        message: 'API Error - spintowin/assign',
        post_vars: res,
      });
    }
  },

  logError(payload, rootState) {
    try {
      console.log('%cERROR LOG', 'color:#f00');
      const { api, host } = getEndpoint();
      const endpoint = 'log';
      const url = api + endpoint + (host === 'localhost' ? '.json' : '');
      payload.store = rootState.appModel.store;
      payload.version = rootState.appModel.content?.version ? rootState.appModel.content.version : null;
      console.log(payload);
      if (!isLocal && host !== 'localhost') {
        axios.post(url, payload, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json',
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
});

// ======================================================================
// EXPORT
// ======================================================================

export const appModel = {
  // initial state
  state,
  // reducers - handle state changes with pure functions
  reducers,
  // selectors - handle state changed based on other state properties
  // selectors,
  // effects - handle state changes with impure functions
  // (use async/await for async actions)
  effects,
};

// ======================================================================
// CODE FROM OLD GAME FOR REFERENCE
// ======================================================================

// generateQr(payload, rootState) {
//   console.log('%c--- generateQr ---', 'color:#3182e0');
//   const timestamp = Date.now().toString().substring(4);
//   const random = rand(111, 999);
//   const gameId = timestamp.toString() + random.toString() + 'qr';
//   const storeId = rootState.internal.getIn(['store', 'uuid']);
//   const { store_miid } = queryString.parse(window.location.search);

//   if (devMode === true || typeof storeId !== 'undefined') {
//     apiRequest(
//       'spintowin/save',
//       {
//         store: storeId,
//         identity: gameId,
//       },
//       (res) => {
//         if (res.status === 'ok') {
//           dispatch.content.setGameId(gameId);
//           dispatch.content.setQrURL(`${qrDomain}?store_miid=${store_miid}&touch_point=game&g=${gameId}`);
//           dispatch.content.setQrState('ready');
//           ReactGA.event({
//             category: 'General',
//             action: 'API Success - spintowin/save',
//           });
//         } else {
//           dispatch.content.logError({
//             message_code: 'error.welcomeTV.generateQr.1',
//             message: 'API Error - /spintowin/save',
//             post_vars: res,
//           });
//           setTimeout(function () {
//             dispatch.content.generateQr();
//             // ReactGA.event({
//             // 	category: 'General',
//             // 	action: 'API Error - spintowin/save'
//             // });
//           }, 2000);
//         }
//       }
//     );
//   } else {
//     dispatch.content.logError({
//       message_code: 'error.welcomeTV.generateQr.2',
//       message: 'Could not generate QR code',
//     });
//   }
// },

// playGame(payload, rootState) {
//   console.log('%c--- playGame ---', 'color:#3183e0');
//   dispatch.content.cancelGameTimeout();
//   dispatch.content.setPlayState('loading');
//   dispatch.content.setShowBlocker(true);
//   const storeId = rootState.internal.getIn(['store', 'uuid']);
//   const gameId = rootState.content.getIn(['gameId']);
//   var endpoint = 'spintowin/play';
//   if (devMode === true) {
//     if (window.location.hostname.indexOf('localhost') >= 0 || window.location.hostname.indexOf('192.168') >= 0) {
//       endpoint = rand(0, 1) === 1 ? 'spintowin/play-win' : 'spintowin/play-lose';
//     }
//   }
//   apiRequest(
//     endpoint,
//     {
//       store: storeId,
//       identity: gameId,
//     },
//     (res) => {
//       console.log(res);
//       if (res !== null && typeof res.status !== 'undefined' && res.status === 'ok') {
//         if (res.messages.resCode === 'winner') {
//           dispatch.content.setWinner(true);
//           dispatch.content.setPrizeName(res.data.title);
//         } else {
//           dispatch.content.setWinner(false);
//         }
//         dispatch.content.cancelGameTimeout();
//         dispatch.content.setPlayState('animating');
//         dispatch.content.setShowBlocker(false);
//         // ReactGA.event({
//         // 	category: 'General',
//         // 	action: 'API Success - spintowin/play'
//         // });
//       } else {
//         if (typeof payload !== 'number') {
//           payload = 1;
//         }
//         if (payload < maxRequestRetries) {
//           payload++;
//           setTimeout(function () {
//             dispatch.content.playGame(payload);
//           }, 1000);
//         } else {
//           clearTimeout(rootState.content.getIn(['cancelGameTimeout']));
//           const history = rootState.content.getIn(['history']);
//           dispatch.content.setShowBlocker(false);
//           history.push('/error' + window.location.search);
//           dispatch.content.logError({
//             message_code: 'error.game.playGame.1',
//             message: 'API Error - spintowin/play',
//             post_vars: res,
//           });
//           // ReactGA.event({
//           // 	category: 'General',
//           // 	action: 'API Error - spintowin/play'
//           // });
//         }
//       }
//     }
//   );
// },

// claim(payload, rootState) {
//   console.log('%c--- claim ---', 'color:#3183e0');
//   dispatch.content.setShowBlocker(true);
//   apiRequest(
//     'spintowin/assign',
//     {
//       store: rootState.internal.getIn(['store', 'uuid']),
//       identity: rootState.content.getIn(['gameId']),
//     },
//     (res) => {
//       console.log(res);
//       const history = rootState.content.getIn(['history']);
//       if (
//         res !== null &&
//         typeof res.status !== 'undefined' &&
//         res.status === 'ok' &&
//         res.messages.resCode === 'assigned'
//       ) {
//         dispatch.content.setPrizeCode(res.data.code);
//         dispatch.content.setShowBlocker(false);
//         history.push('/claimthanks' + window.location.search);
//         // ReactGA.event({
//         // 	category: 'General',
//         // 	action: 'API Success - spintowin/assign'
//         // });
//       } else {
//         if (typeof payload !== 'number') {
//           payload = 1;
//         }
//         if (payload < maxRequestRetries) {
//           payload++;
//           setTimeout(function () {
//             dispatch.content.claim(payload);
//           }, 1000);
//         } else {
//           dispatch.content.setShowBlocker(false);
//           history.push('/error' + window.location.search);
//           dispatch.content.logError({
//             message_code: 'error.game.claim.1',
//             message: 'API Error - /spintowin/assign',
//             post_vars: res,
//           });
//           // ReactGA.event({
//           // 	category: 'General',
//           // 	action: 'API Error - spintowin/assign'
//           // });
//         }
//       }
//     }
//   );
// },

// logError(payload, rootState) {
//   try {
//     console.log('%cERROR LOG', 'color:#f00');
//     payload.store = rootState.internal.getIn(['store', 'uuid']);
//     payload.version = rootState.content.getIn(['content', 'version']);
//     console.log(payload);
//     apiRequest('log', payload, function () {});
//   } catch (e) {
//     //
//   }
// },

//
//
//

// const asyncRequest = async (dispatch, key, { uuid, touch_point, trial_wall_variant }) => {
//   //retrieve the address/location of the server
//   const { api } = getEndpoint();
//   const validVariant = parseVariant(trial_wall_variant);

//   const constructedDeviceName = validVariant ? `samsung_${touch_point}_${validVariant}` : `samsung_${touch_point}`;

//   //combine the base API url with specific server end point
//   // const devEndpoint = `${api}/${override || 'data.json'}`;
//   const url = URL.resolve(api, `get/${uuid}/device-data/${constructedDeviceName}`);

//   //using the API address created, fetch the result
//   const res = await fetch(url, {
//     mode: 'cors',
//     headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   });

//   //if fetch successfully returns the data, convert it to JSON format
//   const { data } = await res.json();

//   const externalAssetPassthroughArray = data !== undefined ? data.ignore_external_asset_passthrough : [];
//   updateMediaUrls(data, externalAssetPassthroughArray, touch_point);

//   //if data is converted to JSON, add the data to the Redux store
//   await dispatch.content.setInitialState(data);

//   return data;
// };

// export function useGetAppData(uuid) {
//   const dispatch = useDispatch();
//   const { touch_point, trial_wall_variant } = useQueryParams();

//   const { data } = useQuery(
//     [
//       'content',
//       {
//         uuid,
//         touch_point,
//         trial_wall_variant,
//       },
//     ],
//     (...args) => asyncRequest(dispatch, ...args)
//   );

//   return data;
// }
